<template>
  <div class="home-wrapper">
    <!-- <TopBar icon="none" /> -->
    <ChatDialog />
    <InputBar />
    <!-- <div id="person-tip" @click="goPersonTip" v-show="personShow">
      <PersonTip />
    </div> -->
  </div>
</template>

<script>
import TopBar from "../../component/TopBar/index";
import ChatDialog from "../../component/ChatDialog/index";
import InputBar from "../../component/InputBar/index";
import PersonTip from "../../component/PersonTip/index";
import { auth, reportSource } from "../../api/login";
import { isWechatEnv } from "../../utils/index";
import { mapState, mapMutations } from "vuex";
import Cookies from "js-cookie";
import store from "../../store";

export default {
  name: "Home",
  components: {
    TopBar,
    ChatDialog,
    InputBar,
    PersonTip,
  },
  computed: {
    ...mapState("dialog", ["userId"]),
  },
  beforeRouteEnter(to, from, next) {
    // 前置守卫鉴权
    if (isWechatEnv()) {
      // 微信鉴权
      auth()
        .then(() => {
          next();
        })
        .catch((err) => {
          // 授权失败兜底，走网页登录
          next("/login");
        });
    } else {
      // 网页鉴权
      if (Cookies.get("userId") || store.state.dialog.userId) {
        next();
      } else {
        next("/login");
      }
    }
  },
  created() {
    if(!this.userId) {
      this.SET_USER_ID(Cookies.get('userId'));
    }

    if (Cookies.get("source") && (this.userId || Cookies.get("open_id"))) {
      // 有来源时，上报用户来源
      reportSource({
        source: Cookies.get("source") ?? "",
        open_id: Cookies.get("open_id") ?? "",
        user_id: this.userId,
      });
    }
  },
  data() {
    return {
      personShow: true,
    };
  },
  mounted() {
    // this.time = setTimeout(() => {
    //   this.personShow = false
    // },2000)
  },
  methods: {
    ...mapMutations("dialog", ["SET_USER_ID"]),
    goPersonTip() {
      this.$router.push({
        path: "/welcome",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;

  #person-tip {
    position: fixed;
    right: 1vw;
    bottom: 16.8vw;
  }
}
</style>
