<template>
  <div class="person-tip">
    <Bubble direction="right" bgcolor="#12a794" fcolor="#fff" v-show="dialogShow">有疑问可以戳我哦~</Bubble>
    <img src="../../assets/img/person.png" />
  </div>
</template>

<script>
import Bubble from '../../component/Bubble/index'

export default {
  name: 'PersonTip',
  components: {
    Bubble
  },
  data() {
    return {
      dialogShow: true
    }
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.dialogShow = false
      clearTimeout(this.timer)
    }, 3000)
  }
}
</script>

<style lang="less">
.person-tip {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  >img {
    width: 20vw;  
  }
}
</style>