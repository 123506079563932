<template>
  <div class="out">
    <div class="detail-answer">
      <img class="robot-logo" src="../../assets/img/logo.jpeg" />
      <div class="content">
        <h3 class="title">你是否想问</h3>
        <div class="problems">
          <div
            class="item"
            v-for="(item, index) in text"
            :key="index"
            @click="sendNewProblems(item)"
          >
            <span class="index">{{ index + 1 }}.</span>
            <span class="item-text">{{ item }}</span>
          </div>
        </div>
        <div class="detail">
          请点击对应的选项进行查看。如果以上问题不是您想要的，请重新描述您的问题。
        </div>
        <div class="left"></div>
      </div>
      <div class="up-and-down">
        <div class="up" @click="upChange">
          <img class="btn-img" :src="upSrc" />
        </div>
        <div class="down" @click="downChange">
          <img class="btn-img" :src="downSrc" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import upDefault from "../../assets/img/great.png";
import upRed from "../../assets/img/great-red.png";
import downDefault from "../../assets/img/step.png";
import downGreen from "../../assets/img/step-green.png";
import { likeAnddislike, sendDialogAI, sendDialogBE } from "../../api/dialog";
import { mapState, mapMutations } from "vuex";
import Cookies from "js-cookie";

export default {
  name: "DetailAnswer",
  data() {
    return {
      problems: [],
      isGreat: false,
      isStep: false,
      upSrc: upDefault,
      downSrc: downDefault,
    };
  },
  props: {
    text: {
      type: Array,
      default: [],
    },
    id: "",
    question: "",
    islike: 0,
    isdislike: 0,
  },
  computed: {
    ...mapState("dialog", ["sessionData", "sessionId", "userId"]),
  },
  methods: {
    ...mapMutations("dialog", ["ADD_SESSION_DATA", "SET_SESSION_ID"]),
    // 点赞
    upChange() {
      if (this.isGreat || this.isStep) return;
      this.isGreat = true;
      this.isGreat ? (this.upSrc = upRed) : (this.upSrc = upDefault);
      likeAnddislike({
        user_id: this.userId,
        message_id: this.id,
        is_like: 1,
        is_dislike: 0
      });
    },
    // 点踩
    async downChange() {
      if (this.isGreat || this.isStep) return;
      this.isStep = !this.isStep;
      this.isStep ? (this.downSrc = downGreen) : (this.downSrc = downDefault);
      const res = await likeAnddislike({
        user_id: this.userId,
        message_id: this.id,
        is_like: 0,
        is_dislike: 1
      });
    },
    // 发送新问题
    async sendNewProblems(message) {
      this.ADD_SESSION_DATA({
        isUser: true,
        type: 1,
        text: message,
        message_id: "0",
      });
      let robotText = null;
      let robotExtra = [];
      let mes = message === "查看更多问题" ? this.question : message
      // 接入对话引擎
      const { queryResult: res, sessionId } = await sendDialogAI({
        sessionId: this.sessionId,
        agentId: "8c457b8f-fd5f-4c95-979d-b1ccbffed775",
        actionAreaId: "default",
        channelId: "default",
        input: {
          query: {
            queryText: mes,
          },
        },
      });
      // 更新对话ID
      this.SET_SESSION_ID(sessionId);
      if(message === "查看更多问题"){
        for (let item of res.outputContexts) {
          if (item.name.indexOf("dymextra") != -1) {
            let arr = [];
            for (let op of item.options) {
              op.displayText && arr.push(op.displayText);
            }
            robotExtra = arr;
            break;
          }
        }
        robotText = robotExtra.length > 0 ? {
          text: {
            LeadingMessage: "您是否想问：",
            EndingMessage:
              "请点击对应的选项进行查看。如果以上问题不是您想要的，请重新描述您的问题。",
            Label: null,
            Text: null,
            Options: [...robotExtra],
          }
        } : {
          text: {
            LeadingMessage: "",
            EndingMessage: "",
            Label: null,
            Text: "您的问题我在努力学习中～",
            Options: [],
          }
        }
      }else{
        let answer = {};
        res.responseText.forEach((value, index) => {
          answer[value.type.toLowerCase()] = JSON.parse(value.content);
        });
        robotText = answer;
      }
      
      const { data } = await sendDialogBE({
        user_id: this.userId,
        open_id: Cookies.get("open_id"),
        session_id: this.sessionId,
        question: message,
        answer: JSON.stringify(robotText),
      });
      if (robotText.text.Options && robotText.text.Options.length > 0) {
        if (robotText.text.Text) {
          this.ADD_SESSION_DATA({
            isUser: false,
            type: 1,
            text: robotText.text.Text,
            message_id: data.id,
            question: message,
          });
        }
        this.ADD_SESSION_DATA({
          isUser: false,
          type: 2,
          text: robotText.text.Options,
          message_id: data.id,
          question: message,
        });
      } else {
        this.ADD_SESSION_DATA({
          isUser: false,
          type: 1,
          text: robotText,
          message_id: data.id,
          question: message,
        });
      }
    },
  },
  mounted() {
    if (this.islike == 1) {
      this.upSrc = upRed;
      this.isGreat = true;
    }
    if (this.isdislike == 1) {
      this.downSrc = downGreen;
      this.isStep = true;
    }
  },
};
</script>

<style lang="less" scoped>
.out {
  width: 100%;
  padding-bottom: 15.4667vw;
  box-sizing: border-box;
}

.detail-answer {
  position: relative;
  padding: 0 4.5333vw;
  margin-top: 10.1333vw;
  display: flex;
  justify-content: space-between;

  .robot-logo {
    width: 12.5333vw;
    height: 12.5333vw;
    border-radius: 50%;
  }

  .content {
    position: relative;
    width: 74.6667vw;
    padding-bottom: 4vw;
    background-color: #fff;
    border-radius: 2.1333vw;

    .detail {
      margin-top: 4.2667vw;
      margin-left: 4vw;
      width: 67.2vw;
      height: 11.7333vw;
      font-size: 3.7333vw;
      line-height: 5.8667vw;
      text-align: center;
      color: #8d8d8d;
    }

    .title {
      margin-top: 4.2667vw;
      margin-left: 4vw;
      font-size: 5.0667vw;
      color: #444954;
      font-weight: 400;
    }

    .problems {
      margin-top: 5.3333vw;
      margin-left: 4vw;

      .item {
        font-weight: 400;
        font-size: 5.0667vw;
        margin-bottom: 3.2vw;

        .index {
          color: #444954;
          margin-right: 1.3333vw;
        }

        .item-text {
          color: #12a794;
        }
      }
    }
  }

  .arrow {
    width: 4.1067vw;
    height: 4.1067vw;
  }

  .left {
    position: absolute;
    .arrow;
    background-color: #fff;
    top: 10%;
    left: -2.6667vw;
    transform: rotate(45deg) translateY(-50%);
    z-index: 0;
  }

  .up-and-down {
    display: flex;
    position: absolute;
    bottom: -15.4667vw;
    left: 66.6667vw;
    width: 28.5333vw;
    justify-content: space-between;

    .btn-img {
      width: 4.8vw;
      height: 4.5333vw;
    }

    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 13.6vw;
      height: 7.4667vw;
      border: 0.2667vw solid #c4c4c4;
      border-radius: 1.0667vw;
      box-sizing: border-box;
    }

    .up {
      .img;
    }

    .down {
      .img;
    }
  }
}
</style>
