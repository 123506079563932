<template>
  <div class="input-bar">
    <div class="flex-wrapper">
      <!-- 切换为语音按钮 -->
      <div class="voice" v-show="isVoiceBtn" @click="changeBtn"></div>
      <input
        class="input-text"
        type="text"
        v-show="isVoiceBtn"
        v-model="currentMessage"
      />
      <button class="send-btn" v-show="isVoiceBtn" @click="sendMessage">
        发送
      </button>
      <!-- 切换为输入框按钮 -->
      <div class="text" v-show="!isVoiceBtn" @click="changeBtn"></div>
      <div
        :class="['vocie-input', { record: isRecording }]"
        v-show="!isVoiceBtn"
        @touchstart.prevent="startRecord"
        @touchend.prevent="endRecord"
      >
        <div :class="[{ 'phone-operate': isRecording }]"></div>
        {{ vocie_text }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { sendDialogAI, sendDialogBE } from "../../api/dialog";
import Cookies from "js-cookie";
import { IatRecorder, blobToBase64 } from "../../utils/index";

export default {
  name: "InputBar",
  data() {
    return {
      isVoiceBtn: true,
      currentMessage: "",
      isRecording: false,
      vocie_text: "安装说话",
      recorder: null,
      voiceRecord: null,
      chunks: [],
      recorder_data: {},
    };
  },
  methods: {
    ...mapActions("dialog", ["dialogPipeline"]),
    ...mapMutations("dialog", ["ADD_SESSION_DATA", "SET_SESSION_ID"]),
    changeBtn() {
      this.$data.isVoiceBtn = !this.$data.isVoiceBtn;
      this.currentMessage = "";
    },
    async sendMessage(event, isAudio) {
      if (this.currentMessage.trim() == "") return;
      if (isAudio) {
        this.ADD_SESSION_DATA({
          isUser: true,
          type: 2,
          text: JSON.stringify(this.recorder_data),
          message_id: "0",
          question: JSON.stringify(this.recorder_data),
        });
      } else {
        // 在sessionData新增数据
        this.ADD_SESSION_DATA({
          isUser: true,
          type: 1,
          text: this.currentMessage,
          message_id: "0",
        });
      }

      // 接入对象引擎
      const { queryResult: res, sessionId } = await sendDialogAI({
        sessionId: this.sessionId,
        agentId: "8c457b8f-fd5f-4c95-979d-b1ccbffed775",
        actionAreaId: "default",
        channelId: "default",
        input: {
          query: {
            queryText: this.currentMessage,
          },
        },
      });

      // 更新对话ID
      this.SET_SESSION_ID(sessionId);
      // 上报信息
      let answer = {};
      res.responseText.forEach((value, index) => {
        answer[value.type.toLowerCase()] = JSON.parse(value.content);
      });
      const robotText = answer;

      let data = {};

      if (isAudio) {
        data = await sendDialogBE({
          user_id: this.userId,
          open_id: Cookies.get("open_id") ?? "",
          session_id: this.sessionId,
          question: JSON.stringify(this.recorder_data),
          answer: JSON.stringify(robotText),
        });
      } else {
        data = await sendDialogBE({
          user_id: this.userId,
          open_id: Cookies.get("open_id") ?? "",
          session_id: this.sessionId,
          question: this.currentMessage,
          answer: JSON.stringify(robotText),
        });
      }

      data = data.data;

      if (robotText.text.Options && robotText.text.Options.length > 0) {
        if (robotText.text.Text) {
          this.ADD_SESSION_DATA({
            isUser: false,
            type: 1,
            text: robotText.text.Text,
            message_id: data.id,
            question: this.currentMessage,
          });
        }
        this.ADD_SESSION_DATA({
          isUser: false,
          type: 2,
          text: robotText.text.Options,
          message_id: data.id,
          question: this.currentMessage,
        });
      } else {
        this.ADD_SESSION_DATA({
          isUser: false,
          type: 1,
          text: robotText,
          message_id: data.id,
          question: this.currentMessage,
        });
      }

      this.currentMessage = "";
    },

    // 开始录音
    startRecord() {
      this.initRecord();
      this.isRecording = true;
      this.vocie_text = "松开结束";
      this.chunks = [];

      // 监听识别结果的变化
      this.recorder.onTextChange = (text) => {
        // 将text当作问题发送
        this.currentMessage = text;
        this.sendMessage(null, true);
      };
    },

    // 结束录制
    endRecord() {
      this.isRecording = false;
      this.vocie_text = "按住说话";
      this.recorder.stop();
      this.voiceRecord.stop();
    },

    // 初始化录音设备
    initRecord() {
      // if (!navigator.mediaDevices) {
      //   navigator.webkitGetUserMedia({ audio: true }).then(
      //     (stream) => {
      //       this.voiceRecord = new window.MediaRecorder(stream);
      //       this.voiceRecord.start();
      //       this.bindEventsVoice();
      //     },
      //     (error) => {
      //       this.$toast(error);
      //     }
      //   );
      // } else {
      //   navigator.mediaDevices.getUserMedia({ audio: true }).then(
      //     (stream) => {
      //       this.voiceRecord = new window.MediaRecorder(stream);
      //       this.voiceRecord.start();
      //       this.bindEventsVoice();
      //     },
      //     (error) => {
      //       this.$toast(error);
      //     }
      //   );
      // }
      this.getUserMedia({ audio: true }, this.gotStream, this.error);
      this.recorder = new IatRecorder();
      this.recorder.start();
    },

    bindEventsVoice() {
      this.voiceRecord.ondataavailable = this.getRecordingData;
      this.voiceRecord.onstop = this.saveRecordingData;
    },

    getRecordingData(e) {
      this.chunks.push(e.data);
    },

    async saveRecordingData() {
      let blob = new Blob(this.chunks, { type: "audio/ogg; codecs=opus" });
      // audioStream = URL.createObjectURL(blob),
      //估算时长
      let duration = parseInt(blob.size / 6600);
      let baseBlob = await blobToBase64(blob);
      if (duration <= 0) {
        this.$toast('说话时间太短')
        return;
      }
      if (duration > 60) {
        duration = 60;
      }
      this.recorder_data = {
        duration,
        recorder: baseBlob,
      };
    },

    getUserMedia(constrains, success, error) {
      if (navigator.mediaDevices.getUserMedia) {
        //最新标准API
        navigator.mediaDevices
          .getUserMedia(constrains)
          .then(success)
          .catch(error);
      } else if (navigator.webkitGetUserMedia) {
        //webkit内核浏览器
        navigator
          .webkitGetUserMedia(constrains)
          .then(success)
          .catch(error);
      } else if (navigator.mozGetUserMedia) {
        //Firefox浏览器
        navagator
          .mozGetUserMedia(constrains)
          .then(success)
          .catch(error);
      } else if (navigator.getUserMedia) {
        //旧版API
        navigator
          .getUserMedia(constrains)
          .then(success)
          .catch(error);
      }
    },

    gotStream(stream) {
      this.voiceRecord = new window.MediaRecorder(stream);
      this.voiceRecord.start();
      this.bindEventsVoice();
    },

    error(error) {
      console.log("访问媒体设备失败：", error.name, error.message);
    },
  },
  mounted() {},
  computed: {
    ...mapState("dialog", ["sessionId", "sessionData", "userId"]),
  },
};
</script>

<style lang="less" scoped>
.input-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 16vw;
  background-color: #f6f5fa;
  user-select: none;

  .flex-wrapper {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .voice {
    width: 8.8vw;
    height: 8.8vw;
    border: 0.5333vw solid #666;
    border-radius: 50%;
    background: url("../../assets/img/voice.png") no-repeat center center;
    background-size: 3.4347vw 4.64vw;
  }

  .input-text {
    width: 67.4667vw;
    height: 11.4667vw;
    box-sizing: border-box;
    border: none;
    outline: none;
    font-size: 4.2667vw;
    padding-left: 2.6667vw;
    margin-left: 3.7333vw;
    margin-right: 3.7333vw;
  }

  .send-btn {
    width: 12vw;
    height: 6.9333vw;
    background-color: transparent;
    border: none;
    font-size: 4.5333vw;
    font-weight: 400;
    line-height: 6.9333vw;
    text-align: justify;
    color: #444954;
  }

  .text {
    width: 8.8vw;
    height: 8.8vw;
    border: 0.5333vw solid #666;
    border-radius: 50%;
    background: url("../../assets/img/vector.png") no-repeat center center;
    background-size: 4vw 3.6667vw;
  }

  .vocie-input {
    width: 80.2667vw;
    height: 11.4667vw;
    line-height: 11.4667vw;
    text-align: center;
    background-color: #fff;
    margin-left: 3.7333vw;
  }

  .record {
    background-color: #f7f7f7;
  }

  .phone-operate {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: -2px;
    content: "";
    width: 0%;
    height: 2px;
    background-color: #7bed9f;
    animation: loading 1s ease-in-out infinite backwards;
  }
}

@keyframes loading {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
</style>
