<template>
  <div class="robot-answer">
    <img class="robot-logo" src="../../assets/img/logo.jpeg" />
    <div class="content-wrapper">
      <Bubble :content="content"></Bubble>
      <div class="yuyin-btn" @click="playVoice">
        <img :class="[{'yuyin-logo': !isLoading}, {loading: isLoading}]" :src="yuyinImg" />
        <span class="text">{{loadingText}}</span>
      </div>

      <div class="up-and-down" v-show="show">
        <div class="up" @click="upChange">
          <img class="btn-img" :src="upSrc" />
        </div>
        <div class="down" @click="downChange">
          <img class="btn-img" :src="downSrc" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bubble from "../../component/Bubble/index";
import upDefault from "../../assets/img/great.png";
import upRed from "../../assets/img/great-red.png";
import downDefault from "../../assets/img/step.png";
import downGreen from "../../assets/img/step-green.png";
import Loading from '../../assets/img/loading.png';
import Yuyin from '../../assets/img/yuyin.png';
import { likeAnddislike } from "../../api/dialog";
import { TTSRecorder } from "../../utils/index";
import { mapState } from "vuex";

export default {
  name: "RotbotAnswer",
  components: {
    Bubble,
  },
  props: {
    id: "",
    islike: {
      default: 0,
    },
    isdislike: {
      default: 0,
    },
    show: {
      default: true,
    },
    content: {
      default: {},
    },
  },
  data() {
    return {
      message: "",
      isGreat: false,
      isStep: false,
      upSrc: upDefault,
      downSrc: downDefault,
      yuyinImg: Yuyin,
      isLoading: false,
      loadingText: '转语音'
    };
  },
  computed: {
    ...mapState("dialog", ["userId"]),
  },
  created() {},
  mounted() {
    if (this.islike == 1) {
      this.upSrc = upRed;
      this.isGreat = true;
    }
    if (this.isdislike == 1) {
      this.downSrc = downGreen;
      this.isStep = true;
    }
  },
  methods: {
    // 点赞
    upChange() {
      if (this.isGreat || this.isStep) return;
      this.isGreat = !this.isGreat;
      this.isGreat ? (this.upSrc = upRed) : (this.upSrc = upDefault);
      likeAnddislike({
        user_id: this.userId,
        message_id: this.id,
        is_like: 1,
        is_dislike: 0,
      });
    },

    // 点踩
    async downChange() {
      if (this.isGreat || this.isStep) return;
      this.isStep = !this.isStep;
      this.isStep ? (this.downSrc = downGreen) : (this.downSrc = downDefault);
      const res = await likeAnddislike({
        user_id: this.userId,
        message_id: this.id,
        is_like: 0,
        is_dislike: 1,
      });
    },

    // 播放语音
    playVoice() {
      this.yuyinImg = Loading;
      this.isLoading = true;
      this.loadingText = '加载中'
      let ttsRecorder = new TTSRecorder();
      let content = "";
      content = this.content;
      if(this.content.text && this.content.text.Text) {
        content = this.content.text.Text;
      } 
      ttsRecorder.setParams({
        text: content,
      });
      let self = this;
      ttsRecorder.onWillStatusChange = function(oldStatus, status) {
        if(status == 'endPlay') {
          self.yuyinImg = Yuyin;
          self.isLoading = false;
          self.loadingText = '转语音';
        }
      }
      ttsRecorder.start();
    }
  },
};
</script>

<style lang="less" scoped>
.robot-answer {
  display: flex;
  justify-content: flex-start;
  margin-top: 7.4667vw;
  padding: 0 4.5333vw;

  .content-wrapper {
    position: relative;

    .up-and-down {
      display: flex;
      position: absolute;
      bottom: -0vw;
      right: 0vw;
      width: 28.5333vw;
      justify-content: space-between;

      .btn-img {
        width: 4.8vw;
        height: 4.5333vw;
      }

      .img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 13.6vw;
        height: 7.4667vw;
        border: 0.2667vw solid #c4c4c4;
        border-radius: 1.0667vw;
        box-sizing: border-box;
      }

      .up {
        .img;
      }

      .down {
        .img;
      }
    }
  }

  .robot-logo {
    width: 12.5333vw;
    height: 12.5333vw;
    border-radius: 50%;
  }

  .yuyin-logo {
    position: absolute;
    top: 50%;
    left: 2.6667vw;
    transform: translateY(-50%);
    width: 5.3333vw;
    height: 5.3333vw;
  }

  .loading {
    position: absolute;
    left: 2vw;
    top: 20%;
    width: 5.3333vw;
    height: 5.3333vw;
    animation: spin 3s linear infinite;
  }

  .yuyin-btn {
    position: relative;
    margin-top: 2.6667vw;
    width: 25.8667vw;
    height: 8.2667vw;
    background-color: #fff;
    border-radius: 7.4667vw;
    box-shadow: 0px 0px 6px rgba(182, 182, 182, 0.25);

    .text {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 9.3333vw;
      transform: translateY(-50%);
      color: #b5b5b5;
      font-size: 4.2667vw;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
