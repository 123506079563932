<template>
  <div class="chat-dialog">
    <div
      v-for="(value, index) in sessionData"
      :key="index"
      :id="'chat-dialog' + index"
    >
      <RotbotAnswer
        v-if="value.type == 1 && value.isUser == false"
        :islike="value.is_like"
        :isdislike="value.is_dislike"
        :id="value.message_id"
        :show="value.show"
        :content="value.text"
      ></RotbotAnswer>
      <UserAnswer v-if="value.isUser == true && value.type==1">{{ value.text }}</UserAnswer>
      <UserVoice v-if="value.isUser == true && value.type==2" :voice="value.question"/>
      <DetailAnswer
        v-if="value.type == 2 && value.isUser == false"
        :text="value.text"
        :id="value.message_id"
        :question="value.question"
        :islike="value.is_like"
        :isdislike="value.is_dislike"
      ></DetailAnswer>
    </div>
  </div>
</template>

<script>
import RotbotAnswer from "../../component/RobotAnswer/index";
import UserAnswer from "../../component/UserAnswer/index";
import DetailAnswer from "../../component/DetailAnswer/index";
import UserVoice from '../../component/UserVoice/index.vue';
import { HtmlFilter } from "../../utils/index";
import { getHistoryMessage } from "../../api/dialog";
import { mapState, mapMutations } from "vuex";
import Cookies from "js-cookie";
import { inverseArray } from "../../utils/index";

export default {
  name: "ChatDialog",
  components: {
    RotbotAnswer,
    UserAnswer,
    DetailAnswer,
    UserVoice
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("dialog", ["sessionData", "userId"]),
  },
  methods: {
    ...mapMutations("dialog", ["ADD_SESSION_DATA"]),
  },
  async mounted() {
    let params = null;
    if (Cookies.get("userId")) {
      params = { userId: Cookies.get("userId") };
    } else if (Cookies.get("open_id")) {
      params = { open_id: Cookies.get("open_id") };
    }
    const { data } = await getHistoryMessage(params);
    let newArray = inverseArray(data);
    if (newArray && newArray.length > 0) {
      newArray.forEach((element, index) => {
        // console.log(element);
        let machineData = JSON.parse(HtmlFilter(element.machineDataData.text));

        this.ADD_SESSION_DATA({
          ...element.userData,
          message_id: element.id,
          question: element.userData.text
        });

        if (element.machineDataData.type == 2) {
          this.ADD_SESSION_DATA({
            ...element.machineDataData,
            text: machineData.text?.Options ?? [],
            message_id: element.id,
            question: element.userData.text
          });
        } else {
          this.ADD_SESSION_DATA({
            ...element.machineDataData,
            text: machineData,
            message_id: element.id,
            question: element.userData.text
          });
          if (machineData.text.Options && machineData.text.Options.length > 0) {
            this.ADD_SESSION_DATA({
              ...element.machineDataData,
              text: machineData.text.Options,
              type: 2,
              message_id: element.id,
              question: element.userData.text
            });
          }
        }
      });
    }
    this.ADD_SESSION_DATA({
      isUser: false,
      type: 1,
      text: "您好，我是肾病科普智能机器人肾小智，您有什么问题吗？",
      message_id: "0",
      show: false,
    });
  },
  updated() {
    document
      .getElementById(`chat-dialog${this.sessionData.length - 1}`)
      .scrollIntoView();
  },
};
</script>

<style lang="less" scoped>
.chat-dialog {
  position: fixed;
  // top: 11.7333vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 16vw;
  overflow: auto;
  background-color: #f7f7f9;
}
</style>
