<template>
  <div class="user-answer">
    <img class="user-logo" src="../../assets/img/logo.jpeg" />
    <div class="content">
      {{value}}
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserAnswer',
  components: {

  },
  data() {
    return {
      value: ''
    }
  },
  mounted() {
    if(this.$slots.default[0].text) {
      this.value = this.$slots.default[0].text
    }
  }
}
</script>

<style lang="less" scoped>
@base_color: #4DA495;
.user-answer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 6.4vw;
  margin-right: 2vw;
  color: #fff;

  .user-logo {
    width: 12.5333vw;
    height: 12.5333vw;
    border-radius: 50%;
    margin-right: 4.5333vw;
    display: none;
  }
  
  .content {
    position: relative;
    display: inline-block;
    max-width: 74.6667vw; 
    line-height: 6.9333vw;
    font-size: 5.0667vw;
    border-radius: 2.1333vw;
    padding: 3.4667vw;
    margin-right: 3.6vw;
    text-align: justify;
    box-sizing: border-box;
    background-color: @base_color;
  }

  .arrow {
    width: 4.1067vw;
    height: 4.1067vw;
  }

  .right {
    display: none;
    position: absolute;
    .arrow;
    background-color: @base_color;
    top: 6.4vw;
    right: -2.4vw;
    transform: rotate(-45deg) translateY(-50%);
  }
}
</style>